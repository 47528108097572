import { EMPTY_SEARCH_PREFERENCE_ENTITY, type SearchPreferenceEntity } from "domain/entity/Common/SearchPreferenceEntity";
import { E_Custom_Dispatch_Event, E_Type_Of_Event, customDispatchEvent } from "helpers/Events";
import type { ChargeDataSearchCriteria } from "presentation/constant/ChargeDataEnquiry/ChargeDataSearchCriteria";
import type { EnabledSearchCriteria } from "presentation/constant/ChargeDataEnquiry/EnabledSearchCriteria";
import { ChargeDetailConstant } from "presentation/constant/ChargeDetailMaintenance/ChargeDetailConstant";
import { useChargeDataSearchVM } from "presentation/hook/ChargeDataEnquiry/useChargeDataSearchVM";
import { useChargeDataSearchTracked } from "presentation/store/ChargeDataEnquiry/ChargeDataSearchProvider";
import { OverflowMenuButton } from "presentation/view/components/OverflowMenuButton";
import { useCallback, useEffect, useMemo, useState } from "react";
import { IconButton, OverflowingSubMenu } from "veronica-ui-component/dist/component/core";

export const ChargeDataSearchHeader: React.FC = () => {
    const [chargeDataSearchState] = useChargeDataSearchTracked();
    const chargeDataSearchVM = useChargeDataSearchVM();

    // const [title, setTitle] = useState('CHARGE SEARCH');
    // const [isList, setIsList] = useState(true);
    // const [isEditCriteria, setIsEditCriteria] = useState(false);
    // const [isDirectory, setIsDirectory] = useState(false);

    // const [isEdit, setIsEdit] = useState(false);
    const [isShowLessDetail, setIsShowLessDetail] = useState(false);
    // const [isFullScreen, setIsFullScreen] = useState(false);
    // const [chargeDataSearchModel, setChargeDataSearchModel] = useState<ChargeDataSearchModel>(EMPTY_CHARGE_DATA_SEARCH_MODEL);
    const [isShowLegend, setIsShowLegend] = useState<boolean>(false);
    const CHARGEDETAIL_CONSTANT = ChargeDetailConstant.Charge;

    // Search Criteria Edit
    const handleCriteriaEditClick = useCallback(() => {
        // customDispatchEvent(E_Type_Of_Event.CHARGE_DATA_SEARCH_EVENT, E_Custom_Dispatch_Event.CHARGE_DATA_EDIT_CRITERIA, { isShowRightCriteriaPanel: !isEditCriteria });
        // setIsEditCriteria(!isEditCriteria);
        // setIsDirectory(false);

        chargeDataSearchVM.handleCriteriaEditClick();
    }, [chargeDataSearchVM]);

    // Directory icon click
    const handleDirectoryClick = useCallback(() => {
        // if (!chargeDataSearchState.isDirectory) {
        // customDispatchEvent(E_Type_Of_Event.CHARGE_DATA_SEARCH_EVENT, E_Custom_Dispatch_Event.CHARGE_DATA_OPEN_DIRECTORY, {});
        // setIsDirectory(!isDirectory);
        // setIsEditCriteria(true);
        chargeDataSearchVM.handleDirectoryClick();
        // }
    }, [chargeDataSearchVM]);

    const handleShowLessDetailClick = useCallback(() => {
        customDispatchEvent(E_Type_Of_Event.CHARGE_DATA_SEARCH_EVENT, E_Custom_Dispatch_Event.CONTAINER_DETAIL_SHOW_DETAIL, {});
        setIsShowLessDetail(!isShowLessDetail);
    }, [isShowLessDetail]);

    const handleFullScreen = useCallback(() => {
        customDispatchEvent(E_Type_Of_Event.MODAL_EVENT, E_Custom_Dispatch_Event.MODAL_FULL_SCREEN_MODE, { isFullScreen: !chargeDataSearchState.isFullScreen, className: 'charge-search-modal' });
        // setIsFullScreen(!isFullScreen);
        // setChargeDataSearchModel({
        //     ...chargeDataSearchModel,
        //     isShowLegendButton: !chargeDataSearchModel.isShowLegendButton
        // });
        chargeDataSearchVM.handleFullScreen();
    }, [chargeDataSearchState.isFullScreen, chargeDataSearchVM]);

    const handleClose = useCallback(() => {
        localStorage.removeItem(E_Custom_Dispatch_Event.REDIRECT_CHARGE_ITEM_FROM_DELIVERY_SUMMARY);
        customDispatchEvent(E_Type_Of_Event.MODAL_EVENT, E_Custom_Dispatch_Event.MODAL_CLOSE, { showChargeSearch: false });
    }, []);

    // Back button done
    const handleBackClick = useCallback(() => {
        // customDispatchEvent(E_Type_Of_Event.CHARGE_DATA_SEARCH_EVENT, E_Custom_Dispatch_Event.CHARGE_DATA_MODAL_BACK_TO_LIST, {});
        // chargeDataSearchVM.closeContainerDetail();
    }, []);

    const handleEditClick = useCallback(() => {
        // customDispatchEvent(E_Type_Of_Event.CHARGE_DATA_SEARCH_EVENT, E_Custom_Dispatch_Event.CONTAINER_DETAIL_EDIT_MODE_ON, {});
        // setIsEdit(true);
    }, []);

    const handleDetailClose = useCallback(() => {
        // if (!isEdit) customDispatchEvent(E_Type_Of_Event.CHARGE_DATA_SEARCH_EVENT, E_Custom_Dispatch_Event.CHARGE_DATA_MODAL_BACK_TO_LIST, {});
        // else customDispatchEvent(E_Type_Of_Event.CHARGE_DATA_SEARCH_EVENT, E_Custom_Dispatch_Event.CONTAINER_DETAIL_EDIT_MODE_OFF, {});
        // setIsEdit(false);
    }, []);

    const handleSaveClose = useCallback(() => {
        // customDispatchEvent(E_Type_Of_Event.CHARGE_DATA_SEARCH_EVENT, E_Custom_Dispatch_Event.CONTAINER_DETAIL_EDIT_MODE_OFF, {});
        // setIsEdit(false);
    }, []);

    const handleSaveSearchPreference = useCallback(() => {
        // customDispatchEvent(E_Type_Of_Event.CHARGE_DATA_SEARCH_EVENT, E_Custom_Dispatch_Event.CHARGE_DATA_PREFERENCE_SAVE_SEARCH, {
        //     currentSearchPreference: chargeDataSearchState.currentSearchPreference,
        //     searchCriteria: chargeDataSearchState.searchCriteria,
        //     enabledSearchCritieria: chargeDataSearchState.enabledSearchCriteria
        // });
        const currentSearchPreference: SearchPreferenceEntity<ChargeDataSearchCriteria, EnabledSearchCriteria> = {
            ...(chargeDataSearchState.currentSearchPreference ?? EMPTY_SEARCH_PREFERENCE_ENTITY),
            searchCriteria: chargeDataSearchState.searchCriteria,
            criteriaDirectory: chargeDataSearchState.enabledSearchCriteria
        }
        chargeDataSearchVM.saveSearchPreference(currentSearchPreference);
    }, [chargeDataSearchState.currentSearchPreference, chargeDataSearchState.enabledSearchCriteria,
    chargeDataSearchState.searchCriteria, chargeDataSearchVM]);

    const preferenceMenu: OverflowingSubMenu[] = useMemo(() => {
        const menuItemArray: OverflowingSubMenu[] = []
        if (chargeDataSearchState.searchPreferences.length) {
            menuItemArray.push({
                title: 'Open preference',
                submenu: chargeDataSearchState.searchPreferences.map(searchPreference => ({
                    title: searchPreference.preferenceName,
                    icon: chargeDataSearchState.currentSearchPreference?.key === searchPreference.key ? 'hph-icon Icon-masterbookmark-fill' : '',
                    command: () => chargeDataSearchVM.loadSearchPreference(searchPreference),//customDispatchEvent(E_Type_Of_Event.CHARGE_DATA_SEARCH_EVENT, E_Custom_Dispatch_Event.CHARGE_DATA_PREFERENCE_OPEN, { searchPreference: searchPreference }),
                }))
            })
        }

        if (chargeDataSearchState.currentSearchPreference?.userEmail === chargeDataSearchState.userEmail) {
            menuItemArray.push({
                title: 'Save preference',
                command: handleSaveSearchPreference,
            })
        }

        menuItemArray.push({
            title: 'Save as new preference...',
            command: () => chargeDataSearchVM.openSaveNewConfirmModal(),//customDispatchEvent(E_Type_Of_Event.CHARGE_DATA_SEARCH_EVENT, E_Custom_Dispatch_Event.CHARGE_DATA_PREFERENCE_SAVE_NEW, {}),
        });

        const ownDashboards = chargeDataSearchState.searchPreferences.filter((searchPreference) => searchPreference.userEmail === chargeDataSearchState.userEmail)
        if (ownDashboards.length) {
            menuItemArray.push({
                title: 'Delete preference...',
                command: () => chargeDataSearchVM.openDeleteConfirmModal(),//customDispatchEvent(E_Type_Of_Event.CHARGE_DATA_SEARCH_EVENT, E_Custom_Dispatch_Event.CHARGE_DATA_PREFERENCE_DELETE, {}),
            })
        }

        return menuItemArray;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chargeDataSearchState.searchPreferences, chargeDataSearchState.currentSearchPreference, chargeDataSearchState.searchCriteria, chargeDataSearchState.enabledSearchCriteria,
    chargeDataSearchState.userEmail, handleSaveSearchPreference]);

    // const onChargeDataSearchChange = (e: any) => {
    //     switch (e.detail.action) {
    //         case E_Custom_Dispatch_Event.CHARGE_DATA_EDIT_CRITERIA_BACK:
    //             setIsEditCriteria(e.detail.data.isEdit);
    //             break;
    //         case E_Custom_Dispatch_Event.CHARGE_DATA_HIDE_RIGHT_PANEL:
    //             setIsEditCriteria(true);
    //             setIsDirectory(e.detail.data.isDirectory || false);
    //             break;
    //         case E_Custom_Dispatch_Event.CHARGE_DATA_PREFERENCE_GET:
    //             setChargeDataSearchModel(e.detail.data.chargeDataSearchModel);
    //             break;
    //         case E_Custom_Dispatch_Event.CHARGE_DATA_PREFERENCE_CURRENT_UPDATE:
    //             setChargeDataSearchModel(prev => ({ ...prev, currentSearchPreference: e.detail.data.currentSearchPreference }));
    //             break;
    //         case E_Custom_Dispatch_Event.CONTAINER_DETAIL_SHOW:
    //             setTitle('CONTAINER DETAILS');
    //             setIsList(false);
    //             break;
    //         case E_Custom_Dispatch_Event.CONTAINER_DETAIL_HIDE:
    //             setTitle('CONTAINER SEARCH');
    //             setIsList(true);
    //             break;
    //     }
    // }

    // useEffect(() => {
    //     customEventListener(E_Type_Of_Event.CHARGE_DATA_SEARCH_EVENT, onChargeDataSearchChange);
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    const overFlowMenu = useMemo(() => <OverflowMenuButton icon='Icon-bookmark' navigationList={preferenceMenu} />, [preferenceMenu]);

    function addLegendToParent(pDivId: string, cDivId: string, headerBox: string) {
        const timer = setInterval(() => {
            const pDiv = document.querySelector(pDivId);
            const cDiv = document.querySelector(cDivId);
            const box = document.querySelector(headerBox);
            const cls: string = cDiv?.getAttribute('class')?.toString() || '';

            if (cls.indexOf('added') >= 0) clearInterval(timer);

            if (pDiv && cDiv && box && cls.indexOf('added') < 0) {
                const pos = pDiv?.getBoundingClientRect();
                const boxPos = box?.getBoundingClientRect();
                cDiv.setAttribute('class', `${cls} added`);
                cDiv.setAttribute('style', `left:${pos && (pos.left - (boxPos?.width ?? 0) * 0.38)}px;top:${boxPos && (boxPos.top + 10)}px;`);
            }
        }, 200);
    }

    useEffect(() => {
        addLegendToParent('#Icon-help-charge-detail-table', '#charge-detail-legend', '#charge-data-search-header-box');
    }, [isShowLegend, chargeDataSearchState.isFullScreen, chargeDataSearchState.isShowLegendButton]);

    const handleLegend = useCallback(() => {
        setIsShowLegend(!isShowLegend);
    }, [isShowLegend]);

    const getListHeader: JSX.Element = useMemo(() => {
        return <>
            <div id="charge-data-search-header-box" className="md-header-box">
                <span className="md-header-title" style={{ flexGrow: 1 }}>{chargeDataSearchState.title}</span>
                {/* <div className="md-header-option" style={{ flexGrow: 0 }}>
                    {(chargeDataSearchModel.isShowLegendButton) && <IconButton id="Icon-help-charge-detail-table" fileName='Icon-help' size='medium' toolTipText={'Legend'} toolTipArrow={false} onClick={handleLegend} />}
                </div> */}
                <div className="md-header-option" style={{ flexGrow: 0, justifyContent: "space-between", flexShrink: 0 }}>
                    {chargeDataSearchState.isEditCriteria && <IconButton fileName='Icon-pen' tooltipDisable={false} size="medium" onClick={handleCriteriaEditClick} toolTipText='Edit Criteria' />}
                    {!chargeDataSearchState.isEditCriteria && <IconButton fileName='Icon-directory' tooltipDisable={false} size="medium" onClick={handleDirectoryClick} toolTipText="Directory" enabled={chargeDataSearchState.isDirectory} />}
                    {<div style={{ width: "32px", height: "32px" }}>{overFlowMenu}</div>}
                </div>
            </div>
            <div className={`md-header-action`} style={{ width: '75px' }}>
                <IconButton fileName={chargeDataSearchState.isFullScreen ? 'Icon-minimise' : 'Icon-enlarge'} tooltipDisable={false} toolTipText={!chargeDataSearchState.isFullScreen ? 'Maximise' : 'Restore'} size="medium" onClick={handleFullScreen} />
                <IconButton fileName="Icon-cross" toolTipText="Close" tooltipDisable={false} size="medium" onClick={handleClose} />
            </div>
            {isShowLegend && <div id='charge-detail-legend' className='detail-legend'>
                <ul>
                    <li><p className='first'><span>{CHARGEDETAIL_CONSTANT.CHARGE_DTL_STATE_LEGEND}</span></p><IconButton fileName="Icon-cross-small" size="small" tooltipDisable={true} onClick={handleLegend} /></li>
                    <li><p className='red'><span>{CHARGEDETAIL_CONSTANT.STATE_RED}</span></p></li>
                    <li><p className='grey'><span>{CHARGEDETAIL_CONSTANT.STATE_GREY}</span></p></li>
                    <li><p className='green'><span>{CHARGEDETAIL_CONSTANT.STATE_GREEN}</span></p></li>
                    <li><p className='blue'><span>{CHARGEDETAIL_CONSTANT.STATE_BLUE}</span></p></li>
                </ul>
            </div>}
        </>
    }, [chargeDataSearchState.title, chargeDataSearchState.isEditCriteria, chargeDataSearchState.isDirectory, handleCriteriaEditClick, handleDirectoryClick, overFlowMenu,
    chargeDataSearchState.isFullScreen, handleFullScreen, handleClose, isShowLegend, CHARGEDETAIL_CONSTANT.CHARGE_DTL_STATE_LEGEND, CHARGEDETAIL_CONSTANT.STATE_RED, CHARGEDETAIL_CONSTANT.STATE_GREY,
    CHARGEDETAIL_CONSTANT.STATE_GREEN, CHARGEDETAIL_CONSTANT.STATE_BLUE, handleLegend]);

    const getDetailHeader: JSX.Element = useMemo(() => {
        return <>
            <div className="md-header-box">
                <span className="md-header-title" style={{ flexGrow: 1 }}>
                    {!chargeDataSearchState.isEdit && <IconButton fileName='Icon-arrow-left' toolTipText='Back' toolTipPlacement="right" onClick={handleBackClick} />}
                    <span>{chargeDataSearchState.title}</span>
                </span>
                <div className="md-header-option" style={{ flexGrow: 0 }}>
                    {!chargeDataSearchState.isEdit && <IconButton fileName='Icon-pen' tooltipDisable={false} size="medium" onClick={handleEditClick} toolTipText='Edit' />}

                    {isShowLessDetail && <IconButton fileName='Icon-minus' size="medium" onClick={handleShowLessDetailClick} toolTipText='Less Details' />}
                    {!isShowLessDetail && <IconButton fileName='Icon-move-updown' size="medium" onClick={handleShowLessDetailClick} toolTipText='More Details' />}
                </div>
            </div>
            <div className={`md-header-action`} style={{ width: (chargeDataSearchState.isEdit ? '106px' : '75px') }}>
                {/* <IconButton fileName="Icon-cross" toolTipText="Close" tooltipDisable={false} size="medium" onClick={handleClose} /> */}
                {chargeDataSearchState.isEdit && <IconButton fileName='Icon-tick' size="medium" onClick={handleSaveClose} toolTipText='Save' />}
                <IconButton fileName={chargeDataSearchState.isFullScreen ? 'Icon-minimise' : 'Icon-enlarge'} tooltipDisable={false} toolTipText={!chargeDataSearchState.isFullScreen ? 'Maximise' : 'Restore'} size="medium" onClick={handleFullScreen} />
                <IconButton fileName='Icon-cross' size="medium" onClick={handleDetailClose} toolTipText='Close' />
            </div>
        </>
    }, [chargeDataSearchState.isEdit, handleBackClick, chargeDataSearchState.title, handleEditClick, isShowLessDetail, handleShowLessDetailClick, handleSaveClose,
    chargeDataSearchState.isFullScreen, handleFullScreen, handleDetailClose]);

    if (chargeDataSearchState.isList) return getListHeader;

    return getDetailHeader;
}