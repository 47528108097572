export interface SearchPreferenceEntity<S,D> {
    key: string
    versionIdentifier: {
        version?: string
    }
    userEmail: string
    preferenceName: string
    searchCriteria: S
    criteriaDirectory: D
    type: string

    [key: string]: string | boolean | null | undefined | Object | S | D
}

export const EMPTY_SEARCH_PREFERENCE_ENTITY: SearchPreferenceEntity<any,any> = {
    key: '',
    versionIdentifier: {},
    userEmail: '',
    preferenceName: '',
    searchCriteria: {},
    criteriaDirectory: {},
    type: ""
}