
import { ChargeConstantCollection } from "constants/charge/ChargeConstantCollection";
import { ManualSystemChargeEntity } from "domain/entity/ChargeDataEnquiry/ManualSystemChargeEntity";
import { E_Custom_Dispatch_Event, E_Type_Of_Event, customDispatchEvent } from "helpers/Events";
import { INITIAL_CHARGE_DATA_ENQUIRY_COL_DEF, transferRowData } from "presentation/constant/ChargeDataEnquiry/ContainerColumnDefinition";
import { useChargeDataSearchVM } from "presentation/hook/ChargeDataEnquiry/useChargeDataSearchVM";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { useChargeDataSearchTracked } from "presentation/store/ChargeDataEnquiry/ChargeDataSearchProvider";
import NbisTable from "presentation/view/components/TableWrapper/NbisTable";
import { useRef } from "react";
import { IconButton } from "veronica-ui-component/dist/component/core";


export const ChargeDataSearchTablePanel = () => {
    const [chargeDataSearchState] = useChargeDataSearchTracked();
    const isShowRightCriteriaPanel = chargeDataSearchState.isShowRightCriteriaPanel;
    const chargeDataSearchVM = useChargeDataSearchVM();
    const [anaInfoState] = useANAInfoTracked();
    const gridRef = useRef<any>(null);

    const tableDoubleClicked = (data: any) => {
        if (data) {
            if (ChargeConstantCollection.systemManualInd.System === (data.data as ManualSystemChargeEntity).manualSysInd) {
                customDispatchEvent(E_Type_Of_Event.CHARGE_DETAIL_REDIRECT_EVENT,
                    E_Custom_Dispatch_Event.REDIRECT_CHARGE_DETAIL_FROM_CHARGE_ITEM_SEARCH,
                    {
                        userName: anaInfoState.userName,
                        data: data.data,
                    });
            } else if (ChargeConstantCollection.systemManualInd.Manual === (data.data as ManualSystemChargeEntity).manualSysInd) {
                customDispatchEvent(E_Type_Of_Event.CHARGE_DETAIL_REDIRECT_EVENT,
                    E_Custom_Dispatch_Event.REDIRECT_MANUAL_CHARGE_DETAIL_FROM_CHARGE_ITEM_SEARCH,
                    {
                        userName: anaInfoState.userName,
                        data: data.data,
                    });
            }
        }
    }

    // const dataSource = useCallback(() => {
    //     if (searchCounter === 0) {
    //         return;
    //     }
    //     return {
    //         getRows: async (params: any) => {
    //             const searchModeEntity: SearchModeEntity = {
    //                 // ...EMPTY_SEARCH_MODE_ENTITY,
    //                 // searchCriteria: {
    //                 //     ...EMPTY_INVENTORY_SEARCH_CRITERIA,
    //                 //     ...searchCriteria,
    //                 // },
    //                 valueCols: params.request.valueCols.map((item: any) => ({ id: item.id, aggFunc: item.aggFunc })),
    //                 groupKeys: params.request.groupKeys,
    //                 pivotMode: params.request.pivotMode,
    //                 rowGroupCols: params.request.rowGroupCols.map((item: any) => item.id),
    //                 startRow: params.request.startRow ?? 0,
    //                 endRow: params.request.endRow ?? 0,
    //                 searchSort: !_.isEmpty(params.request.sortModel) ? {
    //                     sortDirection: params.request.sortModel[0].sort.toUpperCase(),
    //                     sortKey: params.request.sortModel[0].colId
    //                 } : undefined,
    //                 currentGroup: params.request.groupKeys.length < params.request.rowGroupCols.length ? params.request.rowGroupCols[params.request.groupKeys.length].field : "",
    //             }
    //             params.api.hideOverlay();

    //             const result = await chargeDataSearchVM.fetchTableData(searchCriteria, searchModeEntity);
    //             params.success({
    //                 rowData: !_.isEmpty(searchModeEntity.currentGroup) ? result.map(item => ({
    //                     ...item,
    //                     cntrNo: null
    //                 })) : result,
    //                 rowCount: result.length < ((searchModeEntity.endRow - searchModeEntity.startRow) || 0) ? (searchModeEntity.startRow + result.length) : undefined
    //             });
    //         },
    //     };
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [searchCounter, searchCriteria, chargeDataSearchVM]);

    return <div className={`im-charge-data-search-table-panel`} style={{ position: 'relative' }}>
        <div className={`im-charge-data-search-table-panel-content`}>
            <NbisTable
                id='charge-data-search-table'
                columns={INITIAL_CHARGE_DATA_ENQUIRY_COL_DEF}
                // cacheBlockSize={30}
                isNewColumnSetting={true}
                isScrollHighlighted={true}
                groupDisplayType={"groupRows"}
                rowGroupPanelShow={"always"}
                rowSelection={"single"}
                selectionMode={false}
                showPaginator={false}
                showAddIcon={false}
                showDeleteButton={false}
                showReloadIcon={false}
                showUploadIcon={true}
                gridHeight="customHeight"
                customHeight={"calc(100% - 10px)"}
                onRowDoubleClick={tableDoubleClicked}
                rowHeight={28}
                // rowBuffer={20}
                ref={gridRef}
                data={transferRowData(chargeDataSearchState.sysManuCharges)}
                autoMarginLeft={true}
                entrypoint={'chargeDataEnquiry'}
                mustRefresh={true}

                columnSettingIconList={[
                    <>
                        <IconButton fileName={isShowRightCriteriaPanel ? "Icon-angle-left" : "Icon-angle-right"} size="medium" toolTipText={isShowRightCriteriaPanel ? "Close Right Panel" : "Open Right Panel"} toolTipArrow={false} onClick={chargeDataSearchVM.onHideRightCriteriaPanel} />
                    </>
                ]}
            />
        </div>
    </div>
}