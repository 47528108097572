
import _ from "lodash";

export const isNonDefaultValue = (searchCriteria: any, defaultCriteria: any,
    checkBoxRelatedFields: { [key: string]: string[] }, fieldName: string) => {
    if (checkBoxRelatedFields[fieldName])
        return checkBoxRelatedFields[fieldName].find((relatedField) => isFieldNotEmptyAndNotDefault(searchCriteria[relatedField], defaultCriteria[relatedField]))
    return isFieldNotEmptyAndNotDefault(searchCriteria[fieldName], defaultCriteria[fieldName])
}


export const isFieldNotEmptyAndNotDefault = (fieldCheck: any, defaultField: any) => {
    if (!_.isArray(fieldCheck) && (fieldCheck === undefined || fieldCheck === null || fieldCheck === "")) {
        return false
    }
    if (_.isArray(fieldCheck)) {
        return fieldCheck.length > 0 && !fieldCheck.every(value => value === "" || value === null || value === undefined);
    }
    return !_.isEqual(fieldCheck, defaultField)

}