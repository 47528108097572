import { CriteriaDirectoryDocumentCheckboxOption } from "presentation/constant/ChargeDataEnquiry/CriteriaDirectoryCheckboxOption";
import { ChargeConstant } from "presentation/constant/ChargeHeader/ChargeConstant";
import { useChargeDataSearchVM } from "presentation/hook/ChargeDataEnquiry/useChargeDataSearchVM";
import { useChargeDataSearchTracked } from "presentation/store/ChargeDataEnquiry/ChargeDataSearchProvider";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import DateRangePickerComponent from "presentation/view/components/DateRangePickerComponent";
import { useMemo } from "react";
import {
    HPHGroupTitle,
    InputField
} from "veronica-ui-component/dist/component/core";


export const ChargeDataSearchCriteriaEditDocumentPanel = () => {
    const CHARGE_CONSTANT = ChargeConstant.Charge;
    const [chargeDataSearchState] = useChargeDataSearchTracked();
    const chargeDataSearchCriteria = chargeDataSearchState.searchCriteria;
    const chargeDataSearchVM = useChargeDataSearchVM();
    const enabledCriteria = useMemo(() => CriteriaDirectoryDocumentCheckboxOption.find(item => chargeDataSearchState.enabledSearchCriteria[item.key]), [chargeDataSearchState.enabledSearchCriteria]);
    const memoInvNo = useMemo(() =>
        <div className='im-flex-row-item'>
            <InputField
                width='150px'
                maxLength={999}
                label={CHARGE_CONSTANT.INV_CRE_NOTE_NO}
                type="text"
                value={chargeDataSearchCriteria.invNo || ''}
                onChange={(e: any) => chargeDataSearchVM.onInputTextChange(e, 'invNo', true)} />
        </div>
        , [chargeDataSearchCriteria.invNo, chargeDataSearchVM, CHARGE_CONSTANT.INV_CRE_NOTE_NO])
    const memoPreviewRefNo = useMemo(() =>
        <div className='im-flex-row-item'>
            <InputField
                width='150px'
                maxLength={999}
                label={CHARGE_CONSTANT.PREVIEW_REF_NO}
                type="text"
                value={chargeDataSearchCriteria.previewRefNo || ''}
                onChange={(e: any) => chargeDataSearchVM.onInputTextChange(e, 'previewRefNo')} />
        </div>
        , [chargeDataSearchCriteria.previewRefNo, chargeDataSearchVM, CHARGE_CONSTANT.PREVIEW_REF_NO])
    const memoInvDateFromTo = useMemo(() =>
        <div className='im-flex-row-item'>
            <DateRangePickerComponent
                label={CHARGE_CONSTANT.INVOICE_DATE_RANGE}
                width="330px"
                fieldNames={{ startField: "invDateFrom", endField: "invDateTo" }}
                dateRange={{ startDate: chargeDataSearchCriteria.invDateFrom, endDate: chargeDataSearchCriteria.invDateTo }}
                onDatesChange={chargeDataSearchVM.onDateRangeChange}
            />
        </div>
        , [chargeDataSearchCriteria.invDateFrom, chargeDataSearchCriteria.invDateTo, chargeDataSearchVM, CHARGE_CONSTANT.INVOICE_DATE_RANGE])

    const memoPanel = useMemo(() => (
        enabledCriteria ? <div className='im-charge-data-search-criteria-group'>
            <HPHGroupTitle titleText={CHARGE_CONSTANT.DOCUMENT} />
            <CriteriaItemContainer>
                {chargeDataSearchState.enabledSearchCriteria.invDate ? memoInvDateFromTo : null}
            </CriteriaItemContainer>
            <CriteriaItemContainer>
                {chargeDataSearchState.enabledSearchCriteria.invNo ? memoInvNo : null}
                {chargeDataSearchState.enabledSearchCriteria.previewRefNo ? memoPreviewRefNo : null}
            </CriteriaItemContainer>
        </div> : null
    ), [CHARGE_CONSTANT.DOCUMENT, chargeDataSearchState.enabledSearchCriteria.invDate, chargeDataSearchState.enabledSearchCriteria.invNo,
    chargeDataSearchState.enabledSearchCriteria.previewRefNo, enabledCriteria, memoInvDateFromTo, memoInvNo, memoPreviewRefNo]);

    return memoPanel;
};
