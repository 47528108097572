import { EMPTY_CHARGE_DATA_SEARCH_CRITERIA } from "presentation/constant/ChargeDataEnquiry/ChargeDataSearchCriteria";
import { CriteriaDirectoryChargeCheckboxOption } from "presentation/constant/ChargeDataEnquiry/CriteriaDirectoryCheckboxOption";
import { SearchCriteriaCheckboxRelatedField } from "presentation/constant/ChargeDataEnquiry/SearchCriteriaCheckboxRelatedField";
import { ChargeConstant } from "presentation/constant/ChargeHeader/ChargeConstant";
import { useChargeDataSearchVM } from "presentation/hook/ChargeDataEnquiry/useChargeDataSearchVM";
import { useChargeDataSearchTracked } from "presentation/store/ChargeDataEnquiry/ChargeDataSearchProvider";
import { isNonDefaultValue } from "presentation/utils/searchCriteriaUtil";
import { convertDateToDateForComponent } from "presentation/utils/timeUtil";
import { useCallback, useMemo } from "react";
import { HPHGroupTitle, Tags } from "veronica-ui-component/dist/component/core";

export const ChargeDataSearchCriteriaPreviewChargePanel = () => {
    const [chargeDataSearchState] = useChargeDataSearchTracked();
    const searchCriteria = chargeDataSearchState.searchCriteria;
    const chargeDataSearchVM = useChargeDataSearchVM();

    const enabledCriteria = useCallback(() =>
        CriteriaDirectoryChargeCheckboxOption.find(item => chargeDataSearchState.enabledSearchCriteria[item.key]),
        [chargeDataSearchState.enabledSearchCriteria]);

    const isAllDefaultValue = useCallback(() => {
        const attributeField = CriteriaDirectoryChargeCheckboxOption.filter(item => chargeDataSearchState.enabledSearchCriteria[item.key]).map(item => item.key)
        const isAllDefault = !attributeField.find((field) => {
            return isNonDefaultValue(searchCriteria, EMPTY_CHARGE_DATA_SEARCH_CRITERIA, SearchCriteriaCheckboxRelatedField, field)
        })
        return isAllDefault
    }, [searchCriteria, chargeDataSearchState.enabledSearchCriteria]);
    const chargeConstant = ChargeConstant;

    const handleRemoveSearchCriteira = useCallback((fieldName: string, isSearch?: boolean) => {
        chargeDataSearchVM.showLoading();
        chargeDataSearchVM.onRemoveSearchCriteria(fieldName, isSearch);
        chargeDataSearchVM.hideLoading();
    }, [chargeDataSearchVM]);

    const memoPanel = useMemo(() =>
        <>{(enabledCriteria() && !isAllDefaultValue()) ? <div className='im-charge-data-search-criteria-group'>
            <HPHGroupTitle id='im-preview-tag-group-title' titleText='Charge' />
            <div className='im-charge-data-search-criteria-preview-tag-group'>
                {isNonDefaultValue(searchCriteria, EMPTY_CHARGE_DATA_SEARCH_CRITERIA, SearchCriteriaCheckboxRelatedField, 'opsDate') && <Tags
                    label={`${chargeConstant.Charge.OPS_DATE_RANGE} : ${convertDateToDateForComponent(searchCriteria.opsDateFrom)} - ${convertDateToDateForComponent(searchCriteria.opsDateTo)}`}
                    width='fit-content'
                    rounded={true}
                    remove={true}
                    onRemove={() => handleRemoveSearchCriteira('opsDate')}
                />}
                {isNonDefaultValue(searchCriteria, EMPTY_CHARGE_DATA_SEARCH_CRITERIA, SearchCriteriaCheckboxRelatedField, 'stateList') && <Tags
                    label={`${chargeConstant.Charge.STATE}  : ${searchCriteria.stateList?.join(", ")}`}
                    width='fit-content'
                    rounded={true}
                    remove={true}
                    onRemove={() => handleRemoveSearchCriteira('stateList')}
                />}
                {isNonDefaultValue(searchCriteria, EMPTY_CHARGE_DATA_SEARCH_CRITERIA, SearchCriteriaCheckboxRelatedField, 'tarCodeList') && <Tags
                    label={`${chargeConstant.Charge.TARIFF_CODE}  : ${searchCriteria.tarCodeList?.join(", ")}`}
                    width='fit-content'
                    rounded={true}
                    remove={true}
                    onRemove={() => handleRemoveSearchCriteira('tarCodeList')}
                />}
                {isNonDefaultValue(searchCriteria, EMPTY_CHARGE_DATA_SEARCH_CRITERIA, SearchCriteriaCheckboxRelatedField, 'chgOnCompList') && <Tags
                    label={`${chargeConstant.Charge.CHARGE_ON_COMPANY}  : ${searchCriteria.chgOnCompList?.join(", ")}`}
                    width='fit-content'
                    rounded={true}
                    remove={true}
                    onRemove={() => handleRemoveSearchCriteira('chgOnCompList')}
                />}
                {isNonDefaultValue(searchCriteria, EMPTY_CHARGE_DATA_SEARCH_CRITERIA, SearchCriteriaCheckboxRelatedField, 'billToCompList') && <Tags
                    label={`${chargeConstant.Charge.BILL_TO_COMPANY}  : ${searchCriteria.billToCompList?.join(", ")}`}
                    width='fit-content'
                    rounded={true}
                    remove={true}
                    onRemove={() => handleRemoveSearchCriteira('billToCompList')}
                />}
                {isNonDefaultValue(searchCriteria, EMPTY_CHARGE_DATA_SEARCH_CRITERIA, SearchCriteriaCheckboxRelatedField, 'chgTypeList') && <Tags
                    label={`${chargeConstant.Charge.CHARGE_TYPE}  : ${searchCriteria.chgTypeList?.join(", ")}`}
                    width='fit-content'
                    rounded={true}
                    remove={true}
                    onRemove={() => handleRemoveSearchCriteira('chgTypeList')}
                />}
                {isNonDefaultValue(searchCriteria, EMPTY_CHARGE_DATA_SEARCH_CRITERIA, SearchCriteriaCheckboxRelatedField, 'subChgTypeList') && <Tags
                    label={`${chargeConstant.Charge.SUB_CHARGE_TYPE}  : ${searchCriteria.subChgTypeList?.join(", ")}`}
                    width='fit-content'
                    rounded={true}
                    remove={true}
                    onRemove={() => handleRemoveSearchCriteira('subChgTypeList')}
                />}
                {isNonDefaultValue(searchCriteria, EMPTY_CHARGE_DATA_SEARCH_CRITERIA, SearchCriteriaCheckboxRelatedField, 'billingCycleCode') && <Tags
                    label={`${chargeConstant.Charge.BILLING_CYCLE_CODE}  : ${searchCriteria.billingCycleCode}`}
                    width='fit-content'
                    rounded={true}
                    remove={true}
                    onRemove={() => handleRemoveSearchCriteira('billingCycleCode')}
                />}
                {isNonDefaultValue(searchCriteria, EMPTY_CHARGE_DATA_SEARCH_CRITERIA, SearchCriteriaCheckboxRelatedField, 'billingTypeList') && <Tags
                    label={`${chargeConstant.Charge.BILLING_TYPE}  : ${searchCriteria.billingTypeList?.join(", ")}`}
                    width='fit-content'
                    rounded={true}
                    remove={true}
                    onRemove={() => handleRemoveSearchCriteira('billingTypeList')}
                />}
                {isNonDefaultValue(searchCriteria, EMPTY_CHARGE_DATA_SEARCH_CRITERIA, SearchCriteriaCheckboxRelatedField, 'tarCodeDesc') && <Tags
                    label={`${chargeConstant.Charge.TARIFF_CODE_DESC}  : ${searchCriteria.tarCodeDesc}`}
                    width='fit-content'
                    rounded={true}
                    remove={true}
                    onRemove={() => handleRemoveSearchCriteira('tarCodeDesc')}
                />}
                {isNonDefaultValue(searchCriteria, EMPTY_CHARGE_DATA_SEARCH_CRITERIA, SearchCriteriaCheckboxRelatedField, 'expMsg') && <Tags
                    label={`${chargeConstant.Charge.EXCEPTION_MESSAGE}  : ${searchCriteria.expMsg}`}
                    width='fit-content'
                    rounded={true}
                    remove={true}
                    onRemove={() => handleRemoveSearchCriteira('expMsg')}
                />}
                {isNonDefaultValue(searchCriteria, EMPTY_CHARGE_DATA_SEARCH_CRITERIA, SearchCriteriaCheckboxRelatedField, 'manSysInd') && <Tags
                    label={`${chargeConstant.Charge.MANUAL_SYSTEM_IND}  : ${searchCriteria.manSysInd?.join(",")}`}
                    width='fit-content'
                    rounded={true}
                    remove={true}
                    onRemove={() => handleRemoveSearchCriteira('manSysInd')}
                />}
            </div>
        </div> : null
        })</>,
        [chargeConstant.Charge.BILLING_CYCLE_CODE, chargeConstant.Charge.BILLING_TYPE, chargeConstant.Charge.BILL_TO_COMPANY,
        chargeConstant.Charge.CHARGE_ON_COMPANY, chargeConstant.Charge.CHARGE_TYPE, chargeConstant.Charge.EXCEPTION_MESSAGE,
        chargeConstant.Charge.MANUAL_SYSTEM_IND, chargeConstant.Charge.OPS_DATE_RANGE, chargeConstant.Charge.STATE,
        chargeConstant.Charge.SUB_CHARGE_TYPE, chargeConstant.Charge.TARIFF_CODE, chargeConstant.Charge.TARIFF_CODE_DESC,
            enabledCriteria, handleRemoveSearchCriteira, isAllDefaultValue, searchCriteria]);

    return memoPanel;
};
