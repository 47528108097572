import { Permission } from "presentation/constant/ANAInfo/PermissionName";
import { ANAInfoWrapper } from "presentation/view/components/ANAInfo/ANAInfoWrapper";
import { MessageBarWrapper } from "presentation/view/components/MessageBarWrapper";
import { ChargeDataSearchComp } from "presentation/view/section/ChargeDataEnquiry/ChargeDataSearchComp";
import { GridStyles } from "veronica-ui-component/dist/component/core/styled/table.styled";

export const ChargeDataEnquirySearch = () => <ANAInfoWrapper permission={Permission.LOGIN}>
    <MessageBarWrapper>
        {/* <ChargeDataSearchProvider> */}
        <GridStyles />
        <ChargeDataSearchComp />
        {/* </ChargeDataSearchProvider> */}
    </MessageBarWrapper>

</ANAInfoWrapper>;