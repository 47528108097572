import { IMessage } from "@stomp/stompjs";
import { ChargeDataSearchCriteriaEntity } from "domain/entity/ChargeDataEnquiry/ChargeDataSearchCriteriaEntity";
import { ManualSystemChargeEntity } from "domain/entity/ChargeDataEnquiry/ManualSystemChargeEntity";
import { SearchModeEntity } from "domain/entity/ChargeDataEnquiry/SearchModeEntity";
import { SearchPreferenceEntity } from "domain/entity/Common/SearchPreferenceEntity";
import { ChargeDataEnquiryRepository } from "domain/repository/ChargeDataEnquiry/ChargeDataEnquiryRepo";
import { ChargeTypeRepository } from "domain/repository/ChargeType/ChargeTypeRepo";
import { SearchPreferenceRepository } from "domain/repository/Common/SearchPreferenceRepo";
import { CompanyRepository } from "domain/repository/Company/CompanyRepo";
import { SpecialHandlingIndMappingRepository } from "domain/repository/SpecialHanldingInd/SpecialHandlingIndMappingRepo";
import { StandardTariffCodeRepository } from "domain/repository/TariffCode/StandardTariffCodeRepo";
import { ParameterDetailRepository } from "domain/repository/parameter/ParameterDetailRepo";
import { DomUtils } from "helpers/DomUtils";
import { E_Custom_Dispatch_Event, E_Type_Of_Event, customDispatchEvent } from "helpers/Events";
import _, { parseInt } from "lodash";
import { ChargeDataSearchCriteria, EMPTY_CHARGE_DATA_SEARCH_CRITERIA } from "presentation/constant/ChargeDataEnquiry/ChargeDataSearchCriteria";
import { CriteriaDirectoryAllCheckboxOption, CriteriaDirectoryCheckboxOptionList } from "presentation/constant/ChargeDataEnquiry/CriteriaDirectoryCheckboxOption";
import { DEFAULT_ENABLED_CHARGE_DATA_SEARCH_CRITERIA, EnabledSearchCriteria } from "presentation/constant/ChargeDataEnquiry/EnabledSearchCriteria";
import { SearchCriteriaCheckboxRelatedField } from "presentation/constant/ChargeDataEnquiry/SearchCriteriaCheckboxRelatedField";
import { SearchPreferenceType } from "presentation/constant/Common/SearchPreferenceType";
import { ChargeDataSearchModel } from "presentation/model/ChargeDataEnquiry/ChargeDataSearchModel";
import { DropdownProps } from "presentation/model/DropdownProps";
import BaseViewModel from "presentation/viewModel/BaseViewModel";
import { ChangeEvent, Dispatch, SetStateAction } from "react";
import { GroupCheckboxList } from "veronica-ui-component/dist/component/core";

interface ChargeDataSearchVMProps extends BaseViewModel {
    dispatch: [
        Dispatch<SetStateAction<ChargeDataSearchModel>> | ((value: SetStateAction<ChargeDataSearchModel>) => void),
    ]
    companyRepo: CompanyRepository,
    chargeTypeRepo: ChargeTypeRepository,
    standardTariffCodeRepo: StandardTariffCodeRepository,
    parameterDetailRepo: ParameterDetailRepository,
    specialhandlingIndMappingRepo: SpecialHandlingIndMappingRepository,
    chargeDataEnquiryRepo: ChargeDataEnquiryRepository,
    searchPreferenceRepo: SearchPreferenceRepository<ChargeDataSearchCriteria, EnabledSearchCriteria>,
}

export const ChargeDataSearchVM = ({ dispatch, chargeDataEnquiryRepo: ChargeDataEnquiryRepo, companyRepo, chargeTypeRepo, standardTariffCodeRepo, parameterDetailRepo, specialhandlingIndMappingRepo, searchPreferenceRepo }: ChargeDataSearchVMProps) => {
    const [chargeDataSearchDispatch] = dispatch;

    const onRefresh = () => {
        chargeDataSearchDispatch((prevState) => {
            const gruopStateList = prevState.currentTableRef?.current?.api.getServerSideGroupLevelState();
            gruopStateList?.forEach((groupState: any) => {
                prevState.currentTableRef?.current?.api.refreshServerSide({ route: groupState.route, purge: false })
            })
            return {
                ...prevState
            }
        })
    };

    const onWSMessage = (msg: IMessage) => {
        const body = JSON.parse(msg.body) as ManualSystemChargeEntity;
        chargeDataSearchDispatch((prevState) => {
            prevState.currentTableRef?.current?.api.forEachNode((rowNode: any) => {
                if (body.cntrNo === rowNode.data.cntrNo) {
                    rowNode.setData(body);
                }
            });
            return {
                ...prevState
            }
        })
    }

    const onCriteriaDirectoryDropdown = (e: any) => {
        chargeDataSearchDispatch(prevState => {
            prevState.checkboxRef[e?.value]?.scrollIntoView()
            return {
                ...prevState,
                highlightedCheckboxKey: e?.value ?? e
            }
        })
    }

    const onCloseScreen = () => {
        document.dispatchEvent(new CustomEvent('closeChargeDataSearch'))
    }

    const updateCheckboxRef = (checkboxRef: { [key: string]: HTMLElement | null }) => {
        chargeDataSearchDispatch(prevState => ({
            ...prevState,
            checkboxRef: checkboxRef
        }))
    }

    const updateCheckboxValue = (checked: boolean, key: string) => {
        chargeDataSearchDispatch(prevState => {
            let newState: ChargeDataSearchModel = { ...prevState };

            if (key === CriteriaDirectoryAllCheckboxOption.key) {
                CriteriaDirectoryCheckboxOptionList.forEach(item => {
                    item.checkboxList?.forEach(item => {
                        newState = {
                            ...newState,
                            checkboxValue: {
                                ...newState.checkboxValue,
                                [item.key]: checked
                            }
                        }
                    })
                })
            }
            CriteriaDirectoryCheckboxOptionList.find(item => item.option.key === key)?.checkboxList?.forEach(item => {
                newState = {
                    ...newState,
                    checkboxValue: {
                        ...newState.checkboxValue,
                        [item.key]: checked
                    }
                }
            })

            newState = {
                ...newState,
                checkboxValue: {
                    ...newState.checkboxValue,
                    [key]: checked
                }
            }

            return newState
        })
    }

    const onSaveCheckboxValue = () => {
        chargeDataSearchDispatch(prevState => {
            const searchCriteriaFieldName = Object.keys(EMPTY_CHARGE_DATA_SEARCH_CRITERIA).concat(Object.keys(SearchCriteriaCheckboxRelatedField))
            const disabledCriteria = Object.keys(prevState.checkboxValue).filter((item) => !prevState.checkboxValue[item] && searchCriteriaFieldName.includes(item))

            const resetSearchCriteria = disabledCriteria.reduce((obj, fieldName) => {
                //By default, we use disabled field name to retrieve default value from EMPTY_INVENTORY_SEARCH_CRITERIA,
                //If related field found, we use related field to retrieve default value.
                let resetSearchCriteriaPerField = { [fieldName]: EMPTY_CHARGE_DATA_SEARCH_CRITERIA[fieldName] }
                if (SearchCriteriaCheckboxRelatedField[fieldName]) {
                    resetSearchCriteriaPerField = SearchCriteriaCheckboxRelatedField[fieldName].reduce((objPerField, fieldNamePerField) => {
                        return {
                            ...objPerField,
                            [fieldNamePerField]: EMPTY_CHARGE_DATA_SEARCH_CRITERIA[fieldNamePerField],
                        }
                    }, {})
                }
                return {
                    ...obj,
                    ...resetSearchCriteriaPerField
                };
            }, {});

            return {
                ...prevState,
                searchCriteria: { ...prevState.searchCriteria, ...resetSearchCriteria },
                searchCounter: prevState.searchCounter !== 0 ? prevState.searchCounter + 1 : 0,
                enabledSearchCriteria: prevState.checkboxValue,
                isFilterSearchCriteria: true,
                isEditSearchCriteria: true,
                isEditCriteria: true,
            }
        })
    }

    const onResetCheckboxValue = () => {
        chargeDataSearchDispatch(prevState => {
            return {
                ...prevState,
                checkboxValue: { ...DEFAULT_ENABLED_CHARGE_DATA_SEARCH_CRITERIA },
            }
        })
    }

    const onRemoveSearchCriteria = (fieldName: string, isSearch: boolean = true) => {
        //By default, we use disabled field name to retrieve default value from EMPTY_INVENTORY_SEARCH_CRITERIA,
        //If related field found, we use related field to retrieve default value.
        let resetSearchCriteriaPerField = { [fieldName]: EMPTY_CHARGE_DATA_SEARCH_CRITERIA[fieldName] }
        if (SearchCriteriaCheckboxRelatedField[fieldName]) {
            resetSearchCriteriaPerField = SearchCriteriaCheckboxRelatedField[fieldName].reduce((objPerField, fieldNamePerField) => {
                return {
                    ...objPerField,
                    [fieldNamePerField]: EMPTY_CHARGE_DATA_SEARCH_CRITERIA[fieldNamePerField],
                }
            }, {})
        }

        chargeDataSearchDispatch(prevState => {
            return {
                ...prevState,
                searchCounter: isSearch ? prevState.searchCounter + 1 : prevState.searchCounter,
                searchCriteria: { ...prevState.searchCriteria, ...resetSearchCriteriaPerField },
            }
        })
    }

    const onRemoveAllSearchCriteria = () => {
        DomUtils.addOnlySearchClass();
        chargeDataSearchDispatch(prevState => {
            prevState.currentTableRef?.current?.api.setServerSideDatasource({
                getRows: (params: any) => {
                    params.success({
                        rowData: [],
                        rowCount: 0
                    })
                },
            })
            // customDispatchEvent(E_Type_Of_Event.CHARGE_DATA_SEARCH_EVENT, E_Custom_Dispatch_Event.CHARGE_DATA_EDIT_CRITERIA_BACK, { isEdit: false });
            return {
                ...prevState,
                searchCounter: 0,
                searchCriteria: EMPTY_CHARGE_DATA_SEARCH_CRITERIA,
                isEditCriteria: false,
            }
        })
    }

    const onHideRightCriteriaPanel = () => {
        chargeDataSearchDispatch(prevState => {
            if (prevState.isShowRightCriteriaPanel) {
                // customDispatchEvent(E_Type_Of_Event.CHARGE_DATA_SEARCH_EVENT, E_Custom_Dispatch_Event.CHARGE_DATA_HIDE_RIGHT_PANEL, {});
                DomUtils.removeWithSearchClass();
            } else {
                // customDispatchEvent(E_Type_Of_Event.CHARGE_DATA_SEARCH_EVENT, E_Custom_Dispatch_Event.CHARGE_DATA_HIDE_RIGHT_PANEL, { isDirectory: !prevState.isFilterSearchCriteria });
                DomUtils.addWithSearchClass();
            }

            return {
                ...prevState,
                isEditCriteria: true,
                isDirectory: false,
                isShowRightCriteriaPanel: !prevState.isShowRightCriteriaPanel,
            };
        });
    }

    // Open search criteria panel not preview panel
    const onEditSearchCriteria = (searchCounter: number) => {
        DomUtils.addWithSearchClass();

        if (searchCounter === 0) DomUtils.addOnlySearchClass();
        else DomUtils.addWithSearchClass();
    }

    // Open directory criteria panel not search criteria preview panel
    const onSwitchSearchCriteriaMode = (searchCounter: number) => {
        DomUtils.addWithSearchClass();

        if (searchCounter === 0) DomUtils.addOnlySearchClass();
        else DomUtils.addWithSearchClass();
    }

    const onSearch = async (counter?: number) => {
        DomUtils.addWithSearchClass();

        chargeDataSearchDispatch(prevState => {

            const tempCounter = counter ?? prevState.searchCounter;

            const obj: { [x: string]: boolean } = {};
            const objDispatch: { [x: string]: boolean } = { isEdit: true };
            if (tempCounter === 0) {
                obj.isShowRightCriteriaPanel = true;
                objDispatch.isEdit = true;
                obj.isEdit = true;
                obj.isEditSearchCriteria = false;
                obj.isEditCriteria = true;
            } else {
                obj.isEditSearchCriteria = !prevState.isEditSearchCriteria;
            }

            // customDispatchEvent(E_Type_Of_Event.CHARGE_DATA_SEARCH_EVENT, E_Custom_Dispatch_Event.CHARGE_DATA_EDIT_CRITERIA_BACK, objDispatch);
            return { ...prevState, ...obj, searchCounter: tempCounter + 1 };
        });
    }

    const fetchTableData = async (searchCriteria: ChargeDataSearchCriteria, searchModeEntity: SearchModeEntity, setCriteria: boolean = false): Promise<any> => {
        DomUtils.addWithSearchClass();

        const groupCriteriaList: ChargeDataSearchCriteriaEntity[] = searchModeEntity.groupKeys.map((item, index) => ({
            key: searchModeEntity.rowGroupCols[index], value: item
        }))

        return await ChargeDataEnquiryRepo.getManualSystemCharges({
            searchCriteria: searchCriteria,
            startRow: searchModeEntity.startRow,
            endRow: searchModeEntity.endRow,
            searchSort: searchModeEntity.searchSort,
            currentGroup: searchModeEntity.currentGroup,
            groupCriteria: groupCriteriaList,
        }).then((data) => {
            chargeDataSearchDispatch(prevState => {
                // customDispatchEvent(E_Type_Of_Event.CHARGE_DATA_SEARCH_EVENT, E_Custom_Dispatch_Event.CHARGE_DATA_PREFERENCE_GET, {
                //     chargeDataSearchModel: {
                //         ...prevState,
                //         isShowLegendButton: true,
                //     }
                // });
                if (data && data.toString().startsWith("Error:")) {
                    return {
                        ...prevState,
                        docHdrs: [],
                    };
                } else {
                    if (!setCriteria) {
                        return {
                            ...prevState,
                            sysManuCharges: data,
                        }
                    } else {
                        return {
                            ...prevState,
                            sysManuCharges: data,
                            searchCriteria: searchCriteria,
                        }
                    }
                }
            })
            if (data && data.toString().startsWith("Error:")) {
                return { "chargeDataSearchFail": data.toString() };
            }
        }).catch((error) => {
            chargeDataSearchDispatch(prevState => {
                return {
                    ...prevState,
                    sysManuCharges: [],
                }
            })
            return { "chargeDataSearchFail": error.message };
        });
    }

    const loadDropdownOption = async () => {
        await companyRepo.getAllCompanyForCombobox().then(
            companies => {
                let companyCodeDropdownOptions = companies?.map((company) => ({
                    dropdownLabel: company.companyCode,
                    tagLabel: company.companyCode,
                    value: company.companyCode,
                })) ?? []
                companyCodeDropdownOptions = _.orderBy(companyCodeDropdownOptions, "dropdownLabel");

                chargeDataSearchDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        billToCompDropdownOptions: companyCodeDropdownOptions,
                        chgOnCompDropdownOptions: companyCodeDropdownOptions,
                    }
                }))
            }
        );

        await chargeTypeRepo.getAllChargeTypesForCombobox().then(
            chargeTypes => {
                let newChargeTypes = _.orderBy(chargeTypes, ["chargeType", "subChargeType"]);
                let chargeTypeDropdownOptions: DropdownProps[] = [];
                let subChargeTypeDropdownOptions: { [key: string]: DropdownProps[] } = {};
                newChargeTypes.forEach(chgTypeEty => {
                    const chgTypeExisted = chargeTypeDropdownOptions.find(chgType =>
                        chgType.value === chgTypeEty.chargeType);
                    if (!chgTypeExisted) {
                        chargeTypeDropdownOptions.push({
                            dropdownLabel: chgTypeEty.chargeType,
                            tagLabel: chgTypeEty.chargeType,
                            value: chgTypeEty.chargeType
                        })
                    }
                    if (chgTypeEty.subChargeType) {
                        if (!subChargeTypeDropdownOptions[chgTypeEty.chargeType]) {
                            subChargeTypeDropdownOptions[chgTypeEty.chargeType] = [];
                        }
                        subChargeTypeDropdownOptions[chgTypeEty.chargeType].push({
                            dropdownLabel: chgTypeEty.subChargeType,
                            tagLabel: chgTypeEty.subChargeType,
                            value: chgTypeEty.subChargeType
                        });
                    }
                });

                chargeDataSearchDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        chgTypeDropdownOptions: chargeTypeDropdownOptions,
                        subChgTypeDropdownOptions: subChargeTypeDropdownOptions
                    }
                }))
            }
        )

        await standardTariffCodeRepo.getAllStandardTariffCodes().then(
            tarCodes => {
                const tarCodeDropdownOptions = tarCodes.map((result) => ({
                    dropdownLabel: result.tariffCode,
                    tagLabel: result.tariffCode,
                    value: result.tariffCode,
                })) ?? [];

                chargeDataSearchDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        tarCodeDropdownOptions: tarCodeDropdownOptions,
                    }
                }))
            }
        )

        await parameterDetailRepo.getAllParameterDtlsByParameterCode("HANDLING_TERMINAL").then(
            parDtls => {
                const handlingTmlDropdownOptions = parDtls?.map((parDtl) => ({
                    dropdownLabel: parDtl.parameterDtlCode,
                    tagLabel: parDtl.parameterDtlCode,
                    value: parDtl.parameterDtlCode,
                })) ?? []

                chargeDataSearchDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        handlingTmlDropdownOptions: handlingTmlDropdownOptions
                    }
                }))
            }
        )

        await parameterDetailRepo.getAllParameterDtlsByParameterCode("TML_CODE").then(
            parDtls => {
                const tmlCodeDropdownOptions = parDtls?.map((parDtl) => ({
                    dropdownLabel: parDtl.parameterDtlCode,
                    tagLabel: parDtl.parameterDtlCode,
                    value: parDtl.parameterDtlCode,
                })) ?? []

                chargeDataSearchDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        fromTmlDropdownOptions: tmlCodeDropdownOptions,
                        toTmlDropdownOptions: tmlCodeDropdownOptions,
                    }
                }))
            }
        )

        await specialhandlingIndMappingRepo.getAllSpecialHandlingIndMappings().then(
            specialHandlingCodeList => {
                const specialHandlingCodeDropdownOptions = specialHandlingCodeList?.map((specialHandlingCode) => ({
                    dropdownLabel: specialHandlingCode.specialHandlingCode,
                    tagLabel: specialHandlingCode.specialHandlingCode,
                    value: specialHandlingCode.specialHandlingCode,
                })) ?? []

                chargeDataSearchDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        specHandingCodeDropdownOptions: specialHandlingCodeDropdownOptions,
                        specHandingCodeD1DropdownOptions: specialHandlingCodeDropdownOptions,

                    }
                }))
            }
        )

        await parameterDetailRepo.getAllParameterDtlsByParameterCode("CNTR_TYPE").then(
            cntrTypes => {
                const cntrTypeDropdownOptions = cntrTypes?.map((cntrType) => ({
                    dropdownLabel: cntrType.parameterDtlCode,
                    tagLabel: cntrType.parameterDtlCode,
                    value: cntrType.parameterDtlCode,
                })) ?? []

                chargeDataSearchDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        typeDropdownOptions: cntrTypeDropdownOptions,
                    }
                }))
            }
        )

        await parameterDetailRepo.getAllParameterDtlsByParameterCode("CNTR_SIZE").then(
            cntrSizes => {
                const containerSizeDropdownOptions = cntrSizes?.map((containerSize) => ({
                    dropdownLabel: containerSize.parameterDtlCode,
                    tagLabel: containerSize.parameterDtlCode,
                    value: containerSize.parameterDtlCode,
                })) ?? []

                chargeDataSearchDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        sizeGroupDropdownOptions: containerSizeDropdownOptions
                    }
                }))
            }
        )
    }

    // const onDamageCodeActive = () => {
    //     chargeDataSearchDispatch(prevState => ({
    //         ...prevState, damageCodeActive: !prevState.damageCodeActive
    //     }))
    // }

    // const onHoldConditionCodeActive = () => {
    //     chargeDataSearchDispatch(prevState => ({
    //         ...prevState, holdConditionCodeActive: !prevState.holdConditionCodeActive
    //     }))
    // }

    // const onEquipmentCodeActive = () => {
    //     chargeDataSearchDispatch(prevState => ({
    //         ...prevState, equipmentCodeActive: !prevState.equipmentCodeActive
    //     }))
    // }

    // const onSpecialHandlingConditionCodeActive = () => {
    //     chargeDataSearchDispatch(prevState => ({
    //         ...prevState, specialHandlingConditionCodeActive: !prevState.specialHandlingConditionCodeActive
    //     }))
    // }

    // const onDangerousCodeActive = () => {
    //     chargeDataSearchDispatch(prevState => ({
    //         ...prevState, dangerousCodeActive: !prevState.dangerousCodeActive
    //     }))
    // }

    // const onReeferConditionCodeActive = () => {
    //     chargeDataSearchDispatch(prevState => ({
    //         ...prevState, reeferConditionCodeActive: !prevState.reeferConditionCodeActive
    //     }))
    // }

    const onDocumentActive = () => {
        chargeDataSearchDispatch(prevState => ({
            ...prevState, documentActive: !prevState.documentActive
        }))
    }

    const onCoVslVoyChange = (inputData: { co?: string, vsl?: string, voy?: string }, fieldName: { co: string, vsl: string, voy: string }) => {
        chargeDataSearchDispatch(prevState => ({
            ...prevState,
            searchCriteria: {
                ...prevState.searchCriteria,
                [fieldName.co]: inputData?.co?.toUpperCase().replace(/\s+/g, ''),
                [fieldName.vsl]: inputData?.vsl?.toUpperCase().replace(/\s+/g, ''),
                [fieldName.voy]: inputData?.voy?.toUpperCase().replace(/\s+/g, ''),
            }
        }))
    }

    // const onYardLocationRangeChange = (inputData: { blk?: string, stk?: string, lane?: string, tier?: string }, fieldName: { blk: string, stk: string, lane: string, tier: string }) => {
    //     const blk = inputData.blk ?? ''
    //     const stk = inputData.stk ?? ''
    //     const lane = inputData.lane ?? ''
    //     const tier = inputData.tier ?? ''
    //     const stkInNumber = stk === '' ? null : parseInt(stk)
    //     const laneInNumber = lane === '' ? null : parseInt(lane)
    //     const tierInNumber = tier === '' ? null : parseInt(tier)

    //     chargeDataSearchDispatch(prevState => ({
    //         ...prevState,
    //         searchCriteria: {
    //             ...prevState.searchCriteria,
    //             [fieldName.blk]: blk,
    //             [fieldName.stk]: stk ? (isNaN(stkInNumber ?? 0) ? prevState.searchCriteria[fieldName.stk] : stkInNumber) : null,
    //             [fieldName.lane]: lane ? (isNaN(laneInNumber ?? 0) ? prevState.searchCriteria[fieldName.lane] : laneInNumber) : null,
    //             [fieldName.tier]: tier ? (isNaN(tierInNumber ?? 0) ? prevState.searchCriteria[fieldName.tier] : tierInNumber) : null,
    //         }
    //     }))
    // }

    const onDropdownChange = (e: any, fieldName: string) => {
        chargeDataSearchDispatch(prevState => ({
            ...prevState,
            searchCriteria: {
                ...prevState.searchCriteria,
                [fieldName]: e?.value ?? (_.isEmpty(e) ?
                    (fieldName === "subChgTypeList" ? [] : '') : e),
            }
        }))
    }

    const onDatePickerChange = (value: Date, fieldName: string) => {
        chargeDataSearchDispatch(prevState => ({
            ...prevState,
            searchCriteria: {
                ...prevState.searchCriteria,
                [fieldName]: value,
            }
        }))
    }

    const onDateRangeChange = (startDate: any, endDate: any, dateFields: { startField: string, endField: string }) => {
        chargeDataSearchDispatch(prevState => ({
            ...prevState,
            searchCriteria: {
                ...prevState.searchCriteria,
                [dateFields.startField]: startDate,
                [dateFields.endField]: endDate,
            }
        }))
    }

    const onMultipleDropdownChange = (e: any, fieldName: string) => {
        chargeDataSearchDispatch(prevState => {
            return {
                ...prevState,
                searchCriteria: {
                    ...prevState.searchCriteria,
                    [fieldName]: _.uniq(e?.map((item: DropdownProps) => item.value)),
                }
            }
        });
    };

    const onCheckboxChange = (checked: boolean, fieldName: string) => {
        chargeDataSearchDispatch(prevState => ({
            ...prevState,
            searchCriteria: {
                ...prevState.searchCriteria,
                [fieldName]: checked,
            }
        }))
    }

    const onMultipleCheckboxChange = (e: (GroupCheckboxList | undefined)[], fieldNames: string[]) => {
        const value = fieldNames.reduce((obj, fieldName) => {
            return {
                ...obj,
                [fieldName]: !!e.find((checkboxProp) => checkboxProp?.key === fieldName),
            };
        }, {});
        chargeDataSearchDispatch(prevState => ({
            ...prevState,
            searchCriteria: {
                ...prevState.searchCriteria,
                ...value
            }
        }))
    }

    const onRadioChange = (e: any, fieldName: string) => {
        chargeDataSearchDispatch(prevState => ({
            ...prevState,
            searchCriteria: {
                ...prevState.searchCriteria,
                [fieldName]: e.key
            }
        }))
    };

    const onSliderRangeChange = (e: string, fieldName: string) => {
        const inputData = e ?? ''
        const inputDataInNumber = inputData === '' ? null : parseInt(inputData)
        chargeDataSearchDispatch(prevState => ({
            ...prevState,
            searchCriteria: {
                ...prevState.searchCriteria,
                [fieldName]: isNaN(inputDataInNumber ?? 0) ? prevState.searchCriteria[fieldName] : inputDataInNumber,
            }
        }))
    };

    const onInputTextChange = (e: ChangeEvent<HTMLInputElement>, fieldName: string, toUpperCase: boolean = false) => {
        const val = toUpperCase ? e.target.value.toString().toUpperCase() : e.target.value
        chargeDataSearchDispatch(prevState => ({
            ...prevState,
            searchCriteria: {
                ...prevState.searchCriteria,
                [fieldName]: fieldName === 'uniqueCntrNo' ? val.toString().toUpperCase() : val,
            }
        }))
    };

    const onTextAreaChange = (e: ChangeEvent<HTMLTextAreaElement>, fieldName: string, toUpperCase: boolean = false) => {
        const val = toUpperCase ? e.target.value.toString().toUpperCase() : e.target.value
        chargeDataSearchDispatch(prevState => ({
            ...prevState,
            searchCriteria: {
                ...prevState.searchCriteria,
                [fieldName]: fieldName === 'cntrList' ?
                    (val && val.toString().includes(",") ? val.toString().split(",") : (val ? [val.toString()] : [])) :
                    e.target.value.toString(),
            }
        }))
    };

    const onExpandableToggleChange = (e: ChangeEvent<HTMLInputElement>, fieldName: string) => {
        chargeDataSearchDispatch(prevState => ({
            ...prevState,
            searchCriteria: {
                ...prevState.searchCriteria,
                [fieldName]: e.target.value
            }
        }))
    };

    // Save new preference
    const openSaveNewConfirmModal = () => {
        chargeDataSearchDispatch(prevState => ({ ...prevState, isShowSaveNewConfirmModal: true, }));
    };

    const openDeleteConfirmModal = () => {
        chargeDataSearchDispatch(prevState => ({
            ...prevState,
            isShowDeleteConfirmModal: true,
        }))
    };

    const closeConfirmModal = () => {
        chargeDataSearchDispatch(prevState => ({
            ...prevState,
            isShowSaveNewConfirmModal: false,
            isShowDeleteConfirmModal: false,
        }))
    };

    const setUserEmail = (userEmail: string) => {
        chargeDataSearchDispatch(prevState => ({
            ...prevState,
            userEmail: userEmail
        }))
    }

    const saveNewSearchPreference = async (userEmail: string, searchCriteria: ChargeDataSearchCriteria, enabledSearchCriteria: EnabledSearchCriteria, preferenceName: string) => {
        let saveSuccess = true
        let result: SearchPreferenceEntity<ChargeDataSearchCriteria, EnabledSearchCriteria> | null = null
        try {
            result = await searchPreferenceRepo.createNewSearchPreference({
                key: userEmail + preferenceName,
                versionIdentifier: {},
                userEmail: userEmail,
                preferenceName: preferenceName,
                searchCriteria: searchCriteria,
                criteriaDirectory: enabledSearchCriteria,
                type: SearchPreferenceType.CHG_ENQ_CRITERIA
            })
            if (_.isEmpty(result)) {
                saveSuccess = false
            }
        } catch (e: any) {
            saveSuccess = false
        }

        chargeDataSearchDispatch(prevState => {
            const obj: ChargeDataSearchModel = {
                ...prevState,
                ...saveSuccess && {
                    isShowSaveNewConfirmModal: false,
                    isShowDeleteConfirmModal: false,
                    currentSearchPreference: result
                }
            };
            customDispatchEvent(E_Type_Of_Event.CHARGE_DATA_SEARCH_EVENT, E_Custom_Dispatch_Event.CHARGE_DATA_PREFERENCE_CURRENT_UPDATE, { currentSearchPreference: result });
            return { ...obj };
        })

        loadAllSearchPreference(userEmail)
    }

    const loadAllSearchPreference = async (userEmail: string) => {
        await searchPreferenceRepo.getSearchPreferencesByEmailAndType(userEmail, SearchPreferenceType.CHG_ENQ_CRITERIA).then(
            searchPreferences => {
                chargeDataSearchDispatch(prevState => {
                    const dataSearchPreferences: SearchPreferenceEntity<ChargeDataSearchCriteria, EnabledSearchCriteria>[] = searchPreferences?.filter(searchPreference => searchPreference.userEmail === prevState.userEmail) ?? [];
                    customDispatchEvent(E_Type_Of_Event.CHARGE_DATA_SEARCH_EVENT, E_Custom_Dispatch_Event.CHARGE_DATA_PREFERENCE_GET, {
                        chargeDataSearchModel: {
                            ...prevState,
                            searchPreferences: [...dataSearchPreferences],
                        }
                    });
                    return {
                        ...prevState,
                        searchPreferences: [...dataSearchPreferences]
                    };
                })
            }
        )
    }

    const loadSearchPreference = (searchPreference: SearchPreferenceEntity<ChargeDataSearchCriteria, EnabledSearchCriteria>) => {
        searchPreferenceRepo.getSearchPreferencesByEmailAndType(searchPreference.userEmail, searchPreference.type).then(data => {
            if (data && _.isArray(data)) {
                const currentPreference = data?.find(preference => preference.preferenceName === searchPreference.preferenceName);
                if (currentPreference) {
                    chargeDataSearchDispatch(prevState => {
                        const obj: ChargeDataSearchModel = {
                            ...prevState,
                            currentSearchPreference: currentPreference,
                            enabledSearchCriteria: { ...currentPreference.criteriaDirectory },
                            checkboxValue: { ...currentPreference.criteriaDirectory },
                            searchCriteria: { ...EMPTY_CHARGE_DATA_SEARCH_CRITERIA, ...currentPreference.searchCriteria },
                        };
                        // customDispatchEvent(E_Type_Of_Event.CHARGE_DATA_SEARCH_EVENT, E_Custom_Dispatch_Event.CHARGE_DATA_PREFERENCE_CURRENT_UPDATE, { currentSearchPreference: searchPreference });
                        return { ...obj };
                    })
                    return;
                }
            }
            return searchPreference;
        });
    }

    const saveSearchPreference = async (currentSearchPreference: SearchPreferenceEntity<ChargeDataSearchCriteria, EnabledSearchCriteria>) => {
        searchPreferenceRepo.updateSearchPreference(currentSearchPreference).then(data => {
            chargeDataSearchDispatch(prevState => ({
                ...prevState,
                currentSearchPreference: data,
            }));
        })
    }

    const deleteSearchPreference = async (userEmail: string, preferenceName: string) => {
        try {
            await searchPreferenceRepo.deleteSearchPreferenceByKey(userEmail + "-" + preferenceName).then(data => {
                chargeDataSearchDispatch(prevState => {
                    const obj: ChargeDataSearchModel = {
                        ...prevState,
                        isShowSaveNewConfirmModal: false,
                        isShowDeleteConfirmModal: false,
                        currentSearchPreference: prevState.currentSearchPreference?.preferenceName === preferenceName ? null : prevState.currentSearchPreference
                    };
                    customDispatchEvent(E_Type_Of_Event.CHARGE_DATA_SEARCH_EVENT, E_Custom_Dispatch_Event.CHARGE_DATA_PREFERENCE_CURRENT_UPDATE, { currentSearchPreference: prevState.currentSearchPreference?.preferenceName === preferenceName ? null : prevState.currentSearchPreference });
                    return { ...obj };
                })
            })
        } catch (e: any) {
        }

        loadAllSearchPreference(userEmail)
    }

    const showLoading = () => {
        chargeDataSearchDispatch(prevState => {
            return {
                ...prevState,
                isLoading: true
            }
        })
    }

    const hideLoading = () => {
        chargeDataSearchDispatch(prevState => {
            return {
                ...prevState,
                isLoading: false
            }
        })
    }

    const handleCriteriaEditClick = () => {
        chargeDataSearchDispatch(prevState => {
            onEditSearchCriteria(prevState.searchCounter);
            return {
                ...prevState,
                isEditSearchCriteria: !prevState.isEditSearchCriteria && prevState.isFilterSearchCriteria ? true : !prevState.isEditCriteria,
                isShowRightCriteriaPanel: true,
                isFilterSearchCriteria: !prevState.isEditSearchCriteria && prevState.isFilterSearchCriteria ? true : !prevState.isFilterSearchCriteria,
                isDirectory: !prevState.isEditSearchCriteria && prevState.isFilterSearchCriteria ? false : !prevState.isDirectory,
                isEditCriteria: !prevState.isEditSearchCriteria && prevState.isFilterSearchCriteria ? true : !prevState.isEditCriteria
            }
        });
    }

    const handleDirectoryClick = () => {
        chargeDataSearchDispatch(prevState => {
            onSwitchSearchCriteriaMode(prevState.searchCounter);
            return {
                ...prevState,
                isShowRightCriteriaPanel: true,
                isFilterSearchCriteria: true,
                isEditSearchCriteria: true,
                checkboxValue: prevState.enabledSearchCriteria,
                highlightedCheckboxKey: null,
                isDirectory: false,
                isEditCriteria: true
            }
        });
    }

    const handleFullScreen = () => {
        chargeDataSearchDispatch(prevState => ({
            ...prevState,
            isFullScreen: !prevState.isFullScreen,
            isShowLegendButton: !prevState.isShowLegendButton,
        }))
    }

    // const openContainerDetail = (container: ContainerEntity) => {
    //     // DomUtils.addContainerDetailClass();
    //     chargeDataSearchDispatch(prevState => ({ ...prevState, openedContainerNo: container.cntrNo, isShowContainerDetail: true, isEditSearchCriteria: false, }));
    //     customDispatchEvent(E_Type_Of_Event.INVENTORY_SEARCH_EVENT, E_Custom_Dispatch_Event.CONTAINER_DETAIL_SHOW, {});
    // }

    // const closeContainerDetail = () => {
    //     // DomUtils.removeContainerDetailClass();
    //     // document.dispatchEvent(new CustomEvent('ws-inventory-search-show'))
    //     customDispatchEvent(E_Type_Of_Event.INVENTORY_SEARCH_EVENT, E_Custom_Dispatch_Event.CONTAINER_DETAIL_HIDE, {});
    //     chargeDataSearchDispatch(prevState => ({ ...prevState, openedContainerNo: '', isShowContainerDetail: false, }))
    // }

    return {
        showLoading: showLoading,
        hideLoading: hideLoading,
        onRefresh: onRefresh,
        onWSMessage: onWSMessage,
        onSwitchSearchCriteriaMode,
        onCriteriaDirectoryDropdown: onCriteriaDirectoryDropdown,
        onCloseScreen: onCloseScreen,
        updateCheckboxRef: updateCheckboxRef,
        updateCheckboxValue: updateCheckboxValue,
        onSaveCheckboxValue: onSaveCheckboxValue,
        onResetCheckboxValue: onResetCheckboxValue,
        onRemoveSearchCriteria: onRemoveSearchCriteria,
        onRemoveAllSearchCriteria: onRemoveAllSearchCriteria,
        onHideRightCriteriaPanel,
        onEditSearchCriteria: onEditSearchCriteria,
        onSearch,
        fetchTableData: fetchTableData,
        loadDropdownOption: loadDropdownOption,
        onDocumentActive: onDocumentActive,
        // onDamageCodeActive: onDamageCodeActive,
        // onHoldConditionCodeActive: onHoldConditionCodeActive,
        // onEquipmentCodeActive: onEquipmentCodeActive,
        // onSpecialHandlingConditionCodeActive: onSpecialHandlingConditionCodeActive,
        // onDangerousCodeActive: onDangerousCodeActive,
        // onReeferConditionCodeActive: onReeferConditionCodeActive,
        onCoVslVoyChange: onCoVslVoyChange,
        // onYardLocationRangeChange: onYardLocationRangeChange,
        onDatePickerChange: onDatePickerChange,
        onDateRangeChange: onDateRangeChange,
        onDropdownChange: onDropdownChange,
        onMultipleDropdownChange: onMultipleDropdownChange,
        onCheckboxChange: onCheckboxChange,
        onMultipleCheckboxChange: onMultipleCheckboxChange,
        onRadioChange: onRadioChange,
        onSliderRangeChange: onSliderRangeChange,
        onInputTextChange: onInputTextChange,
        onTextAreaChange: onTextAreaChange,
        onExpandableToggleChange: onExpandableToggleChange,
        openSaveNewConfirmModal: openSaveNewConfirmModal,
        openDeleteConfirmModal: openDeleteConfirmModal,
        closeConfirmModal: closeConfirmModal,
        setUserEmail: setUserEmail,
        loadAllSearchPreference: loadAllSearchPreference,
        loadSearchPreference: loadSearchPreference,
        saveNewSearchPreference: saveNewSearchPreference,
        saveSearchPreference: saveSearchPreference,
        deleteSearchPreference: deleteSearchPreference,
        // openContainerDetail: openContainerDetail,
        // closeContainerDetail: closeContainerDetail,
        handleCriteriaEditClick: handleCriteriaEditClick,
        handleDirectoryClick: handleDirectoryClick,
        handleFullScreen: handleFullScreen,
    }
}